.Content {
  padding: 1rem 3rem;
  border-radius: 8px;
}

@media screen and (max-width: 750px) {
  .Content {
    padding: 0.5rem;
  }
}
