.Products {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
}
.ProductCard {
    margin: 0.5rem;
    height: 36rem;
}
.CoverContainer {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 16px auto;
    position: relative;
    width: calc(100% - 16px) !important;
}
.ProductCardContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 10rem;
    overflow: auto;
}
.OldPrice {
    color: red;
    text-decoration: line-through;
    margin-right: 0.25rem;
}
.Pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.ProductPrice div[class="ant-card-meta-description"] {
    color: brown;
}   